<template>
	<div>
		<div class="bg-white boderra8 box-shadow0010">
			<div class="h54 align-items-center">
				<div class="title-item center fz16 cure-pointer h100"
					:class="titleIndex == i ? 'co-010085' : 'co-333333'" v-for="(item, i) in titleList" :key="i"
					@click="titleChoose(i)">{{item}}</div>
			</div>

			<div v-if="titleIndex == 0">
				<div class="mart10 paddlr20">
					<div class="fz16 co-333333 font-blod">基本信息</div>
				</div>
				<div class="mart4 flex-wrap">
					<div class="fill-in-item align-items-center">
						<div class="align-items-center fz16 name-area marl20">
							<span class="co-FE3000">*</span>
							<span class="co-333333 name-title marl8">姓名</span>
						</div>
						<div class="marl15">
							<input class="fz16 co-333333 public-input" v-model="nickname" placeholder="请输入真实姓名" />
						</div>
					</div>
					<div class="fill-in-item align-items-center">
						<div class="align-items-center fz16 name-area">
							<span class="co-FE3000">*</span>
							<span class="co-333333 name-title marl8">学校</span>
						</div>
						<div class="marl15">
							<input class="fz16 co-333333 public-input" v-model="sname" placeholder="请输入学校名称" />
						</div>
					</div>
					<div class="fill-in-item align-items-center mart20">
						<div class="align-items-center fz16 name-area marl20">
							<!-- <span class="co-FE3000">*</span> -->
							<span class="co-333333 marl8">专业名称</span>
						</div>
						<div class="marl15">
							<input class="fz16 co-333333 public-input" v-model="zname" placeholder="请输入专业名称" />
						</div>
					</div>
					<div class="fill-in-item align-items-center mart20">
						<div class="align-items-center fz16 name-area">
							<!-- <span class="co-FE3000">*</span> -->
							<span class="co-333333 marl8">平均成绩</span>
						</div>
						<div class="marl15">
							<input class="fz16 co-333333 public-input" v-model="achievement" placeholder="请输入平均成绩" />
						</div>
					</div>
					<div class="fill-in-item align-items-center mart20">
						<div class="align-items-center fz16 name-area marl20">
							<span class="co-FE3000">*</span>
							<span class="co-333333 marl8">手机号</span>
						</div>
						<div class="marl15">
							<input class="fz16 co-333333 public-input" v-model="phone" placeholder="请输入手机号" />
						</div>
					</div>
					<div class="fill-in-item align-items-center mart20">
						<div class="align-items-center fz16 name-area">
							<span class="co-FE3000">*</span>
							<span class="co-333333 marl8">微信号</span>
						</div>
						<div class="marl15">
							<input class="fz16 co-333333 public-input" v-model="wechat" placeholder="请输入微信号" />
						</div>
					</div>
				</div>
				<div class="mart30 paddlr20">
					<div class="fz16 co-333333 font-blod">申请信息</div>
					<div class="mart4 flex-wrap">
						<div class="fill-in-item align-items-center">
							<div class="align-items-center fz16 name-area ">
								<span class="co-333333 marl8">申请国家</span>
							</div>
							<div class="marl15">
								<input class="fz16 co-333333 public-input" v-model="country" placeholder="请输入申请国家" />
							</div>
						</div>
						<div class="fill-in-item align-items-center">
							<div class="align-items-center fz16 name-area">
								<span class="co-333333 marl8">申请专业</span>
							</div>
							<div class="marl15">
								<input class="fz16 co-333333 public-input" v-model="zapply" placeholder="请输入申请专业" />
							</div>
						</div>
						<div class="fill-in-item align-items-center mart20">
							<div class="align-items-center fz16 name-area ">
								<span class="co-333333 marl8">目标院校</span>
							</div>
							<div class="marl15">
								<input class="fz16 co-333333 public-input" v-model="sapply" placeholder="请输入目标院校" />
							</div>
						</div>
						<div class="fill-in-item align-items-center mart20">
							<div class="align-items-center fz16 name-area">
								<span class="co-333333 marl8">入学时间</span>
							</div>
							<div class="marl15">
								<el-date-picker v-model="jointime" @change='datePickerChange' type="date"
									placeholder="请选择入学时间">
								</el-date-picker>
							</div>

						</div>
						<div class="fill-in-item align-items-center mart20">
							<div class="align-items-center fz16 name-area ">
								<span class="co-333333 marl8">申请学位</span>
							</div>
							<div class="marl15" @click="industry=true">
								<input class="fz16 co-333333 public-input" v-model="degree" placeholder="请输入申请学位" />
							</div>
						</div>
						<div class="fill-in-item align-items-center mart20">
							<div class="align-items-center fz16 name-area">
								<span class="co-333333 marl8">作品集</span>
							</div>
							<div class="marl15">
								<input class="fz16 co-010085 public-input" v-model="link" placeholder="请输入作品集链接" />
							</div>
						</div>
					</div>
				</div>

				<div class="center mart308 paddb20">
					<div class="fz18 co-010085 sub-btn center cure-pointer" @click="submit">提交申请</div>
				</div>
			</div>

			<div class=" paddlr20" v-else>
				<div class="bg-white">
					<div class="h69 justify-content-center-between ass-item" :class="i < 9 ? 'boderb1-E6E6E6' : ''"
						v-for="(item, i) in lists" :key="i" @click="toDetail(item)">
						<div>
							<div class="fz16 co-333333 ass-name">评估报告已生成</div>
							<div class="mart6 fz12 co-999999">{{item.create_time}}</div>
						</div>
						<div class="align-items-center">
							<span class="fz16 co-010085">查看</span>
							<img class="marl10 coo_02 display-block" src="../../assets/img/coo_02.png" />
						</div>
					</div>
					<loadMore :state="state" loading_icon></loadMore>
				</div>

			</div>
		</div>

		<div class="center paddt54" v-if="titleIndex == 1">
			<div class="center paddt54">
				<el-pagination background class="center " @current-change="handleCurrentChange"
					layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="学位选择" :append-to-body='false' :lock-scroll='false' v-model="industry" center width='200px'>
			<div class="display-flex align-items-center justify-content-center" v-for="(item,index) in list"
				:key='index' @click="industryChange(index)">
				<div class="fz16  co-333333 h40  cure-pointer hovers">
					{{item.name}}
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'Assessment',
		data() {
			return {
				titleList: ['作品评估', '评估报告'], // 收藏导航
				titleIndex: 0, // 导航选中
				nickname: '', // 真是姓名
				sname: '', // 学校名称
				zname: '', // 专业名称
				achievement: '', // 平均成绩
				phone: '', // 手机号
				wechat: '', // 微信号
				country: '', // 申请国家
				zapply: '', // 申请专业
				sapply: '', // 目标学校名称
				jointime: '', // 入学时间
				degree: '', // 学位
				degreeid: '',
				link: '', // 作品链接
				list: [], // 作品评估
				industry: false,
				lists: [], // 估值报告
				state: 1, // 列表加载状态
				page: 1, // 分页
				loading: false, // 接口加载状态
				size: 16,
				classroomlist: [],
				maxnum:''
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				url: '/api/tags',
				data: {
					type: 'degree',
				},
				success: res => {
					this.list = res;
					console.log(this.list)
				},
				fail: error => {

				}
			});
			this.$req({
				method: 'get',
				data: {
					
					show_page:1,
					
				},
				url: '/api/works',
				success: res => {
					console.log(res,'总条数')
					this.maxnum =res;
				},
				fail: error => {
					
				}
			});
			this.getlist();
		},
		methods: {
			getlist() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 16;
				this.$req({
					method: 'get',
					data: {
						page: this.page,
						size: size
					},
					url: '/api/works',
					success: res => {
						let list = res;
						if (page == 1) {
							this.list = list;
						} else {
							this.list= this.list.concat(list);
						}
						// this.paging();
						if (list.length > 0) {
						    this.state = 0;
						} else {
						    this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						this.state = 3;
					}
				});
				this.$req({
					method: 'get',
					data: {
						
						show_page:1,
						
					},
					url: '/api/works',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 假分页
			paging() {
				let all = this.classroomlist;
				let size = this.size;
				let index = (this.page - 1) * size;
				let arr = [];
				for (let i = 0; i < size; i++) {
					if (index < all.length) {
						arr.push(all[index])
					}
					index++;
				}
		
				
				this.lists = arr;
				// console.log(this.lists)
			},
			industryChange(index) {
				this.degree = this.list[index].name
				this.degreeid = this.list[index].id;
				this.industry = !this.industry;
			},
			// 导航选中
			titleChoose(e) {
				this.titleIndex = e;
				// if (this.titleIndex == 1) {
				// 	this.lists= [];
				// 	this.page = 0;
				// 	this.state = 0;
				// 	this.loading = false;
				// 	this.getlist();
				// }
			},
			// 跳转详情
			toDetail(item) {
				if (item.is_assess == 0) {
					this.$message.error('请等待管理员审核完毕,便可以查看');
					return
				}
				this.$router.push({ //核心语句
					path: '/AssessmentDetail', //跳转的路径
					query: {
						id: item.id
					}
				})
			},
			selectDate(dateData) {
				let date = new Date(dateData)
				let y = date.getFullYear()
				let m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d;
				this.jointime = time;
				console.log(this.jointime)
			},
			datePickerChange(event) {
				this.selectDate(event)
			},
			// 提交
			submit() {
				if (!this.nickname) {
					this.$message.error('请输入姓名');
					return false
				}
				if (!this.sname) {
					this.$message.error('请输入学校名称');
					return false
				}
				var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
				if (!myreg.test(this.phone)) {
					this.$message.error('手机号格式不正确');
					return false;
				}
				if (!this.phone) {
					this.$message.error('请输入手机号');
					return false
				}
				if (!this.wechat) {
					this.$message.error('请输入微信号');
					return false
				}
				
				this.$req({
					method: 'post',
					url: '/api/works',
					data: {
						name: this.nickname,
						school: this.sname,
						profess: this.zname,
						score: this.achievement,
						phone: this.phone,
						wechat_number: this.wechat,
						country: this.country,
						apply_profess: this.zapply,
						target_school: this.sapply,
						entrance_date: this.jointime,
						apply_degree_id: this.degree,
						works: this.link,
					},
					success: res => {
						this.nickname = '';
						this.sname = '';
						this.zname = '';
						this.achievement = '';
						this.phone = '';
						this.wechat = '';
						this.country = '';
						this.zapply = '';
						this.sapply = '';
						this.jointime = '';
						this.degree = '';
						this.link = '';
						this.$message.success('提交成功,等待管理员审核');
					},
					fail: error => {}
				});

			},
			handleCurrentChange(val) {
				this.page = val;
				this.list = [];
				this.loading = false;
				this.getlist();
				document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less">
	.hovers:hover {
		color: #010085;
	}

	.ass-item:hover {
		cursor: pointer;
	}

	.marl15 {

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 355px;
		}
	}

	.ass-item:hover .ass-name {
		color: #010085;
	}

	.title-item {
		padding: 0 20px;
	}

	.fill-in-item {
		width: 50%;

		.name-title {
			letter-spacing: 10px;
		}
	}

	.name-area {
		width: 80px;
	}

	.public-input {
		width: 321px;
		height: 34px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		margin: 0;
		padding: 0;
		padding: 0 16px;
		outline: none;
	}

	.sub-btn {
		width: 135px;
		height: 36px;
		background: #DCFF03;
		border-radius: 2px;
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	.coo_02 {
		width: 14px;
		height: 14px;
	}

	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
